import { createContext, useContext } from 'react'

import type { AppContextProps } from './types'

export const AppContext = createContext<AppContextProps | null>(null)

export const AppContextProvider = AppContext.Provider

export const useApp = (): AppContextProps => {
  const app = useContext(AppContext)

  if (app) {
    return app
  }

  throw new Error('useApp must be used within a AppContextProvider.')
}
