import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Button = dynamic(() => import('./base/button'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Section = dynamic(() => import('./base/section'))
const Image = dynamic(() => import('./base/image'))
const Video = dynamic(() => import('./base/video'))
const Iframe = dynamic(() => import('./base/iframe'))

const Accordion = dynamic(() => import('./widgets/accordion'))
const ArticleCard = dynamic(() => import('./widgets/article-card'))
const CardList = dynamic(() => import('./widgets/card-list'))
const Header = dynamic(() => import('./widgets/header'))
const Footer = dynamic(() => import('./widgets/footer'))
const Tabs = dynamic(() => import('./widgets/tabs'))
const Test = dynamic(() => import('./widgets/test'))
const MainSlideShow = dynamic(() => import('./widgets/main-slideshow'))
const GallerySlideShow = dynamic(() => import('./widgets/gallery-slideshow'))
const PageHeader = dynamic(() => import('./widgets/page-header'))
const Blog = dynamic(() => import('./widgets/blog'))
const BlogList = dynamic(() => import('./widgets/blog-list'))
const VideoList = dynamic(() => import('./widgets/video-list'))
const LazyImage = dynamic(() => import('./widgets/lazy-image'))
const GoTurkiyeVideo = dynamic(() => import('./widgets/go-turkiye-video'))
const GoTurkiyeImage = dynamic(() => import('./widgets/go-turkiye-image'))
const ImageList = dynamic(() => import('./widgets/image-list'))
const ImageSlidesShow = dynamic(() => import('./widgets/image-slideshow'))
const SlidesShow = dynamic(() => import('./widgets/slideshow'))
const SustainabilitySlideshow = dynamic(
  () => import('./widgets/sustainability-slideshow')
)
const SocialMediaShare = dynamic(() => import('./widgets/social-media-share'))
const Hero = dynamic(() => import('./widgets/hero'))
const VideoSlidesShow = dynamic(() => import('./widgets/video-slideshow'))
const HotelSlidesShow = dynamic(() => import('./widgets/hotel-slideshow'))
const SectionSlidesShow = dynamic(() => import('./widgets/section-slideshow'))
const AnimatedHero = dynamic(() => import('./widgets/animated-hero'))
const FacilityList = dynamic(() => import('./widgets/facility-list'))
const ParticlesBanner = dynamic(
  () => import('./widgets/glomil-particles-banner')
)

const GlomilInfoBarDetail = dynamic(
  () => import('./widgets/glomil-info-bar-detail')
)

const AsgInfoBar = dynamic(() => import('./widgets/asg-info-bar'))
const AsgInfoBarList = dynamic(() => import('./widgets/glomil-info-bar-list'))
const AsgBrandCardList = dynamic(() => import('./widgets/asg-brand-card-list'))
const AsgTeamList = dynamic(() => import('./widgets/asg-team-list'))
const AsgPageBackDrop = dynamic(() => import('./widgets/asg-page-back-drop'))
const AsgCarrier = dynamic(() => import('./widgets/asg-carrier'))
const AsgHeader = dynamic(() => import('./widgets/asg-header'))
const AsgFooter = dynamic(() => import('./widgets/asg-footer'))
const AsgMainSlideShow = dynamic(() => import('./widgets/asg-main-slide-show'))
const GlomilMainSlideshow = dynamic(
  () => import('./widgets/glomil-main-slide-show')
)
const AsgLogoArchive = dynamic(() => import('./widgets/asg-logo-archive'))
const AsgRewards = dynamic(() => import('./widgets/asg-rewards'))
const GlomilOrganizationChart = dynamic(
  () => import('./widgets/glomil-organization-chart')
)
const AsgOrganizationChart = dynamic(
  () => import('./widgets/asg-organization-chart')
)
const AsgCookieButton = dynamic(() => import('./widgets/asg-cookie-button'))

export default function getter(name: string) {
  switch (name) {
    case 'card':
      return ArticleCard
    case 'card-list':
      return CardList
    case 'container':
      return Container
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'header':
      return Header
    case 'footer':
      return Footer
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'accordion':
      return Accordion
    case 'tabs':
      return Tabs
    case 'test-component':
      return Test
    case 'main-slide-show':
      return MainSlideShow
    case 'galeri-slider':
      return GallerySlideShow
    case 'page-header':
      return PageHeader
    case 'blog':
      return Blog
    case 'blog-list':
      return BlogList
    case 'video-list':
      return VideoList
    case 'lazy-image':
      return LazyImage
    case 'go-turkiye-video':
      return GoTurkiyeVideo
    case 'go-turkiye-image':
      return GoTurkiyeImage
    case 'image':
      return Image
    case 'image-list':
      return ImageList
    case 'image-slides-show':
      return ImageSlidesShow
    case 'slides-show':
      return SlidesShow
    case 'sustainability-slides-show':
      return SustainabilitySlideshow
    case 'social-media-share':
      return SocialMediaShare
    case 'hero':
      return Hero
    case 'video-slides-show':
      return VideoSlidesShow
    case 'hotel-slides-show':
      return HotelSlidesShow
    case 'section':
      return Section
    case 'section-slides-show':
      return SectionSlidesShow
    case 'animated-hero':
      return AnimatedHero
    case 'facility-list':
      return FacilityList
    case 'video':
      return Video
    case 'asg-info-bar':
      return AsgInfoBar
    case 'glomil-info-bar-list':
      return AsgInfoBarList
    case 'asg-brand-card-list':
      return AsgBrandCardList
    case 'asg-team-list':
      return AsgTeamList
    case 'asg-page-back-drop':
      return AsgPageBackDrop
    case 'asg-carrier':
      return AsgCarrier
    case 'asg-header':
      return AsgHeader
    case 'asg-footer':
      return AsgFooter
    case 'asg-main-slide-show':
      return AsgMainSlideShow
    case 'glomil-main-slide-show':
      return GlomilMainSlideshow
    case 'asg-logo-archive':
      return AsgLogoArchive
    case 'asg-rewards':
      return AsgRewards
    case 'iframe':
      return Iframe
    case 'asg-organization-chart':
      return AsgOrganizationChart
    case 'asg-cookie-button':
      return AsgCookieButton
    case 'glomil-info-bar-detail':
      return GlomilInfoBarDetail
    case 'glomil-particles-banner':
      return ParticlesBanner
    case 'glomil-organization-chart':
      return GlomilOrganizationChart
  }
}
