import React, { useState, useEffect } from 'react'
import Cookies, { CookieSetOptions } from 'universal-cookie'

import Icon from '@/components/base/icon'
import { useApp } from '@/core/contexts/app'

import { CookieName } from './enums'
import { COOKIE_NAMES } from './constants'
import { CookieManagerProps, CookieTabItemProps } from './types'

const CookieManager: React.FunctionComponent<CookieManagerProps> = (props) => {
  const [modalVisibility, setModalVisibility] = useState(
    props.visibility || false
  )
  const [activeAccordion, setActiveAccordion] = useState<string>('required')
  const [cookieObject, setCookieObject] = useState<Record<string, boolean>>({
    performance: false,
    advertising: false,
    required: true,
  })
  const [barVisibility, setBarVisibility] = useState(false)

  const app = useApp()
  const cookie = new Cookies()

  useEffect(() => {
    const settings = cookie.get(CookieName.AllowedSettings)

    if (settings) {
      setCookieObject(settings)
      handleDismiss()
    } else {
      setBarVisibility(true)
    }
  }, [])

  useEffect(() => {
    if (props.visibility) {
      setModalVisibility(props.visibility)
    }
  }, [props.visibility])

  const getTabs = () => {
    const tabs = [
      {
        label: app.settings.translations['cookieRequiredTitle'],
        value: 'required',
        text: app.settings.translations['cookieRequiredText'],
        disabled: true,
      },
      {
        label: app.settings.translations['cookiePerformanceTitle'],
        value: 'performance',
        text: app.settings.translations['cookiePerformanceText'],
      },
      {
        label: app.settings.translations['cookieAdvertisingTitle'],
        value: 'advertising',
        text: app.settings.translations['cookieAdvertisingText'],
      },
    ]

    return tabs
  }

  const handleDismiss = () => {
    setModalVisibility(false)

    if (props.onDismiss) {
      props.onDismiss()
    }
  }

  const handleAddCookie = (
    name: CookieName,
    value: boolean | string,
    settings?: CookieSetOptions
  ) => {
    cookie.set(name, value, settings)
  }

  const handleRemoveCookie = (name: CookieName) => {
    cookie.remove(name)
  }

  const handleUpdateCookies = (isAcceptAll?: boolean) => {
    Object.keys(cookieObject).forEach((key) => {
      const name = COOKIE_NAMES[key]

      if (name) {
        if (isAcceptAll) {
          handleAddCookie(name, true)
        } else {
          if (cookieObject[key] === true) {
            handleAddCookie(name, true)
          } else {
            handleRemoveCookie(name)
          }
        }
      }
    })
  }

  const handleSaveSettings = (isAcceptAll?: boolean) => {
    const settings = isAcceptAll
      ? { performance: true, advertising: true, required: true }
      : cookieObject

    if (settings) {
      if (isAcceptAll) {
        setCookieObject(settings)
      }

      handleAddCookie(CookieName.AllowedSettings, JSON.stringify(settings))
      handleUpdateCookies(isAcceptAll)
      setBarVisibility(false)
    }

    handleDismiss()
  }

  const TabItem = (props: CookieTabItemProps) => {
    if (activeAccordion !== props.name) return null

    const isChecked = props.disabled
      ? true
      : cookieObject[props.name]
      ? true
      : false

    return (
      <div className="cookie-manager-tab-item">
        <div className="item-title">{props.title}</div>
        <div className="item-content">
          <div className="item-text">{props.text}</div>
          <div className="item-value">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(event) => {
                  if (!props.disabled)
                    setCookieObject({
                      ...cookieObject,
                      [props.name]: event.target.checked,
                    })
                }}
                readOnly={props.disabled}
              />
              <span
                className={['toggle-slider', props.disabled && 'disabled']
                  .filter((x) => x)
                  .join(' ')}
              ></span>
              <span className="toggle-text">
                {isChecked
                  ? app.settings.translations['active']
                  : app.settings.translations['deactive']}
                {props.disabled && app.settings.translations['everytime']}
              </span>
            </label>
          </div>
        </div>
      </div>
    )
  }

  const renderTabs = () => {
    const tabs = getTabs()

    return tabs.map((tab, index) => (
      <div
        key={index}
        className={[
          'cookie-manager-tab-title',
          activeAccordion === tab.value && 'is-active',
        ]
          .filter((x) => x)
          .join(' ')}
        onClick={() => setActiveAccordion(tab.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setActiveAccordion(tab.value)
          }
        }}
        role="button"
        tabIndex={0}
      >
        {tab.label}
      </div>
    ))
  }

  const renderTabContent = () => {
    const tabs = getTabs()

    return tabs.map((tab, index) => (
      <TabItem
        title={tab.label}
        text={tab.text}
        disabled={tab.disabled}
        name={tab.value}
        key={index}
      />
    ))
  }

  const renderModal = () => {
    if (!modalVisibility) return null

    return (
      <div className="cookie-manager-modal">
        <div className="cookie-manager-modal-wrapper">
          <div className="cookie-manager-modal-panel">
            <div className="title">
              {app.settings.translations['cookieModalTitle']}
            </div>
            {renderTabs()}
          </div>
          <div className="cookie-manager-modal-content">
            <div className="cookie-manager-tab-content">
              {renderTabContent()}
            </div>
            <div className="cookie-manager-modal-foot">
              <div
                className="cookie-manager-tab-button stable"
                onClick={() => handleSaveSettings()}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSaveSettings()
                  }
                }}
                role="button"
                tabIndex={0}
              >
                {app.settings.translations['cookieSave']}
              </div>
              <div
                className="cookie-manager-tab-button"
                onClick={() => handleSaveSettings(true)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSaveSettings(true)
                  }
                }}
                role="button"
                tabIndex={0}
              >
                {app.settings.translations['cookieAccept']}
              </div>
            </div>
          </div>
          <div
            className="cookie-manager-modal-close"
            onClick={() => handleDismiss()}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleDismiss()
              }
            }}
            role="button"
            tabIndex={0}
          >
            <Icon name="icon-close" />
          </div>
        </div>
        <div
          className="cookie-manager-modal-body"
          onClick={() => handleDismiss()}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleDismiss()
            }
          }}
          role="button"
          tabIndex={0}
        />
      </div>
    )
  }

  const renderBar = () => {
    if (!barVisibility) return null

    return (
      <div className="cookie-manager-bar">
        <p>{app.settings.translations['cookieText']}</p>
        <div className="cookie-manager-buttons">
          <div
            className="cookie-manager-button stable"
            onClick={() => setModalVisibility(true)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setModalVisibility(true)
              }
            }}
            role="button"
            tabIndex={0}
          >
            {app.settings.translations['cookieMore']}
          </div>
          <div
            className="cookie-manager-button"
            onClick={() => handleSaveSettings(true)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSaveSettings(true)
              }
            }}
            role="button"
            tabIndex={0}
          >
            {app.settings.translations['cookieAccept']}
          </div>
        </div>
      </div>
    )
  }

  if (!barVisibility && !modalVisibility) return null

  const classNames = ['cookie-manager', modalVisibility && 'is-open']
    .filter((x) => x)
    .join(' ')

  return (
    <div className={classNames}>
      {renderBar()}
      {renderModal()}
    </div>
  )
}

export default CookieManager
